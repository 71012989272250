import React, { useState, useEffect, useMemo } from 'react';
import { CiCirclePlus } from "react-icons/ci";
import { FaRegTrashAlt } from "react-icons/fa";
import { useAuth } from '../contexts/AuthContext';
import { DocumentMetaInfo } from '../utils/types';
import ProfileModal from '../components/ProfileModal';
// import { TbLogout } from "react-icons/tb";
import { Button } from '../components/button';
import { fetchWithAuth } from '../utils/fetchWithAuth';
import { useNavigate } from 'react-router-dom';
import { User, Token } from '../utils/types';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';

import {
    ArrowRightStartOnRectangleIcon,
    ChevronUpIcon,
    LightBulbIcon,
    ShieldCheckIcon,
    UserCircleIcon,
} from '@heroicons/react/16/solid'

import { Avatar } from '../components/avatar'

import {
    Sidebar,
    SidebarBody,
    SidebarFooter,
    SidebarHeader,
    SidebarHeading,
    SidebarItem,
    SidebarLabel,
    SidebarSection,
    SidebarSpacer,
} from '../components/sidebar'

import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../components/dropdown'

function AccountDropdownMenu({ anchor, onProfileClick }: { anchor: 'top start' | 'bottom end', onProfileClick: () => void }) {
    const navigate = useNavigate();
    const { logout } = useAuth();
    const handleLogout = () => {
        localStorage.removeItem('token');
        logout();
    };

    return (
        <DropdownMenu className="min-w-64" anchor={anchor}>
            <DropdownItem onClick={() => { onProfileClick(); navigate('/settings'); }}>
                <UserCircleIcon />
                <DropdownLabel>Profile Settings</DropdownLabel>
            </DropdownItem>
            {/* <DropdownDivider />
            <DropdownItem href="#">
                <ShieldCheckIcon />
                <DropdownLabel>Privacy policy</DropdownLabel>
            </DropdownItem>
            <DropdownItem href="#">
                <LightBulbIcon />
                <DropdownLabel>Share feedback</DropdownLabel>
            </DropdownItem> */}
            <DropdownDivider />
            <DropdownItem href="#" onClick={handleLogout}>
                <ArrowRightStartOnRectangleIcon />
                <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
        </DropdownMenu>
    )
}

interface NavbarProps {
    documentsMetaInfo: DocumentMetaInfo[];
    setDocumentsMetaInfo: React.Dispatch<React.SetStateAction<DocumentMetaInfo[]>>;
    user: User;
    token: Token;
}

const SideNavbar: React.FC<NavbarProps> = ({ documentsMetaInfo, setDocumentsMetaInfo, user, token }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hoveredDocumentId, setHoveredDocumentId] = useState<string | null>(null);
    // const [documentsMetaInfo, setDocumentsMetaInfo] = useState<DocumentMetaInfo[]>([]);

    const handleProfileClick = () => {
        setIsModalOpen(true);
    };
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (user) {
    //         fetchDocumentsMetaInfo();
    //     }
    // }, []);

    // useEffect(() => {
    //     if (user) {
    //         console.log(user.profilePicture);
    //     }
    // }, [user])

    const fetchDocumentsMetaInfo = async () => {
        try {
          if (!user) return;
    
          const response = await fetchWithAuth(
            `${process.env.REACT_APP_PUBLIC_HOST}/api/documents/?userId=${user._id}`,
            token
          );
          console.log('Fetched response:', response);
    
          if (response && Array.isArray(response.documents)) {
            setDocumentsMetaInfo((prevDocumentsMetaInfo) => {
              if (!isEqual(prevDocumentsMetaInfo, response.documents)) {
                console.log('Documents meta info updated');
                return response.documents;
              } else {
                console.log('Documents meta info is the same, not updating state');
                return prevDocumentsMetaInfo;
              }
            });
          } else {
            console.error('Unexpected response format:', response);
          }
        } catch (error) {
          console.error('Error fetching documents:', error);
        }
      };

    const handleDocumentSelect = (documentId: string) => {
        console.log("Document selected:", documentId);
        if (window.location.pathname !== `/editor/${documentId}`) {
            navigate(`/editor/${documentId}`);
        }
    };

    const handleNewDocument = async () => {
        if (!user) return;

        try {
            const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
            const newDocument = {
                title: "Untitled Document",
                text: "",
                prompt: "",
                wordCount: 0,
                userId: user._id
            };
            const data = await fetchWithAuth(url, token, {
                method: 'POST',
                body: JSON.stringify(newDocument)
            });

            console.log("Document created successfully:", data.id);
            await fetchDocumentsMetaInfo();
            navigate(`/editor/${data.id}`);

        } catch (error) {
            console.error('Error creating document:', error);
        }
    };

    const handleDeleteDocument = async (documentId: string) => {
        if (!user) return;

        try {
            const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
            await fetchWithAuth(url, token, {
                method: 'DELETE',
                body: JSON.stringify({ documentId, userId: user._id })
            });

            await fetchDocumentsMetaInfo();

            // Manually remove the document from the documents array
            console.log("Removing document id: ", documentId);
            setDocumentsMetaInfo(documentsMetaInfo.filter(doc => doc._id !== documentId));
            console.log("Documents after deletion: ", documentsMetaInfo);
            
            // if deleted document is the current document (check url)
            if (window.location.pathname === `/editor/${documentId}`) {
                console.log("Current document deleted, navigating to first document");
                console.log("The first document is: ", documentsMetaInfo[0]._id);
                navigate(`/editor/${documentsMetaInfo[0]._id}`);
            }
        } catch (error) {
            console.error('Error deleting document:', error);
        }
    };

    return (
        <Sidebar>
            <SidebarHeader>
                <img
                    className="px-16"
                    src="/logo.png"
                    alt="MIRAI"
                />
            </SidebarHeader>

            <SidebarBody>
                {/* New Document Button */}
                <SidebarSection>
                    <Button
                        className="mx-4"
                        onClick={handleNewDocument}
                        color="dark"
                    >
                        <div className="flex flex-row items-center space-x-2">
                            <CiCirclePlus className="text-xl" />
                            <div className="">
                                <h2 className="text-md font-bold">New document</h2>
                            </div>
                        </div>
                    </Button>
                </SidebarSection>

                {/* Document List */}
                <SidebarSection className="max-lg:hidden">
                    <SidebarHeading>Documents</SidebarHeading>
                    {documentsMetaInfo.map((doc, index) => (
                        <SidebarItem
                            key={doc._id}
                            className={`w-full items-center rounded-md ${window.location.pathname === `/editor/${doc._id}`
                                ? 'bg-zinc-950/5'
                                : ''
                                }`}
                            onMouseEnter={() => setHoveredDocumentId(doc._id)}
                            onMouseLeave={() => setHoveredDocumentId(null)}
                            onClick={() => handleDocumentSelect(doc._id)}
                        >
                            <div
                                className="flex-1 text-left overflow-hidden whitespace-nowrap text-ellipsis"
                            >
                                {doc.title || `Untitled Document`}
                            </div>
                            {hoveredDocumentId === doc._id && (
                                <FaRegTrashAlt
                                    className="text-red-500 hover:text-red-700 cursor-pointer"
                                    onClick={() => handleDeleteDocument(doc._id)}
                                />
                            )}
                        </SidebarItem>
                    ))}
                </SidebarSection>

                <SidebarSpacer />
            </SidebarBody>

            {/* User Profile */}
            <SidebarFooter className="max-lg:hidden">
                <Dropdown>
                    <DropdownButton as={SidebarItem}>
                        <span className="flex min-w-0 items-center gap-3">
                            <Avatar src={user?.profilePicture ? user.profilePicture : "/profile.png"} className="size-10" square alt="" />
                            <span className="min-w-0">
                                <span className="block truncate text-sm/5 font-medium text-zinc-950">{user?.name ? user.name : "Anonymous User"}</span>
                                <span className="block truncate text-xs/5 font-normal text-zinc-500">
                                    {user?.email ? user.email : "User"}
                                </span>
                            </span>
                        </span>
                        <ChevronUpIcon />
                    </DropdownButton>
                    <AccountDropdownMenu anchor="top start" onProfileClick={handleProfileClick} />
                </Dropdown>
            </SidebarFooter>
            {/* {isModalOpen && <ProfileModal onClose={() => setIsModalOpen(false)} />} */}
        </Sidebar>
    );
};

export default SideNavbar;