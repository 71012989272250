import StarterKit from '@tiptap/starter-kit'

import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import { TextStyle, TextStyleOptions } from '@tiptap/extension-text-style'
import { EditorProvider, useCurrentEditor, FloatingMenu, BubbleMenu, useEditor, EditorContent } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import { Extension } from '@tiptap/core';

import { FaRedo } from "react-icons/fa";
import { FaUndo } from "react-icons/fa";

import { Document } from '../utils/types';

import { useEffect } from 'react'
import { Editor as TiptapEditor } from '@tiptap/core';
import { EditorState } from '@tiptap/pm/state';



interface EditorProps {
    document: Document | null;
    selectedDocumentId: string | null;
    onUpdate: (text: string) => void;
}

const LiteralTab = Extension.create({
    name: 'literalTab',

    addKeyboardShortcuts() {
        return {
            Tab: () => {
                console.log('test');
                return this.editor.commands.insertContent('\t')
            }
        }
    }
})

const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    TextStyle.configure({ types: [ListItem.name] } as Partial<TextStyleOptions>),
    StarterKit.configure({
        bulletList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
            keepMarks: true,
            keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
    }),
    Underline,
    LiteralTab,
]

const editorProps = {
    attributes: {
        class: 'min-h-full prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-5 focus:outline-none text-black px-2 sm:px-4 md:px-8 lg:px-16 xl:px-24',
    },
}

function resetEditorContent(editor: TiptapEditor, newContent: string) {
    editor.commands.setContent(newContent);

    // Clear the history
    const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        schema: editor.state.schema
    });
    editor.view.updateState(newEditorState);
}

export default function Editor({ document, onUpdate }: EditorProps) {

    const editor = useEditor({
        extensions: extensions,
        content: '',
        editorProps: editorProps,
        onBlur: ({ editor }) => {
            onUpdate(editor.getHTML());
        },
    })

    useEffect(() => {
        if (editor && document) {
            const currentContent = editor.getHTML();
            const newContent = document.text;
            if (currentContent !== newContent) {
                resetEditorContent(editor, newContent);
            }
        }
    }, [document, editor]);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (editor) {
                onUpdate(editor.getHTML());
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [editor, onUpdate]);

    return (
        <div className='flex flex-col h-[calc(100vh-100px)] w-full'>
            {/* <h1 className="text-2xl font-bold mb-4">{document.title}</h1> */}
            <div>
                {editor && <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
                    <div className="bubble-menu flex flex-row p-1 rounded-lg bg-white border-1 border-gray-50">
                        <button
                            onClick={() => editor.chain().focus().toggleBold().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <strong>B</strong>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleItalic().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <em>I</em>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().toggleUnderline().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <u>U</u>
                        </button>
                        <button
                            onClick={() => editor.chain().focus().undo().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <FaUndo />
                        </button>
                        <button
                            onClick={() => editor.chain().focus().redo().run()}
                            className={'hover:bg-gray-100 text-black p-2 rounded-lg size-7 flex items-center justify-center text-center'}
                        >
                            <FaRedo />
                        </button>
                    </div>
                </BubbleMenu>}
            </div>
            <div className="flex-1 overflow-y-auto w-full">
                <EditorContent editor={editor} className="h-full" />
            </div>
        </div>
    )
}