import { Token } from './types';

async function fetchWithAuth(url: string, token: Token, options: any = {}) {
    // Setting up default headers
    const defaultHeaders = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };

    // Prepare final options
    const finalOptions = {
        headers: { ...defaultHeaders, ...options.headers }, // Merge headers
        ...options, // Merge the rest of the options
    };

    const response = await fetch(url, finalOptions);

    // if (!response.ok) {
    //     window.location.href = '/login';
    //     return;
    // }

    return response.json();
}


export { fetchWithAuth };