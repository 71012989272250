import React, { createContext, useContext, useState, useEffect, useCallback, ReactNode } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { User, Token, AuthContextType } from '../utils/types';
import Cookies from "js-cookie";

const AuthContext = createContext<AuthContextType>({
  token: null,
  user: null,
  isAuthenticating: true,
  login: () => { },
  logout: () => { },
  authenticate: () => { },
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<Token>(null);
  const [user, setUser] = useState<User>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const authenticate = useCallback(async (token: Token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_PUBLIC_HOST}/api/auth/authenticate`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        console.error('Failed to authenticate');
        if (location.pathname !== '/register') {
          navigate("/login");
        }
      }
      else {
        const data = await response.json();
        console.log(data);
        setToken(token);
        setUser(data.user);
      }
    } catch (error) {
      console.error('Unknown error:', error);
      if (location.pathname !== '/register') {
        navigate("/login");
      }
    }
  }, [navigate, location.pathname]);

  useEffect(() => {
    const storedToken = Cookies.get("token");
    if (storedToken) {
      authenticate(storedToken);
      setToken(storedToken);
    }
    setIsAuthenticating(false);
  }, [authenticate]);

  const login = (token: Token, user: User) => {
    if (token !== null) {
      Cookies.set("token", token, {
        expires: 7,
      });
      setToken(token);
      setUser(user);
    }
  };

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("user");
    setToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ token, user, isAuthenticating, login, logout, authenticate }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};