import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { fetchWithAuth } from "../utils/fetchWithAuth";
import { Subheading } from './heading';
import { Input } from './input';
import { Token } from '../utils/types';

interface DocumentSettingsProps {
    documentId: string;
    token: Token;
}

const DocumentSettings: React.FC<DocumentSettingsProps> = ({ documentId, token }) => {
    const [prompt, setPrompt] = useState<string | null>(null);
    const [maxWordCount, setMaxWordCount] = useState<string | null>(null);

    useEffect(() => {
        if (documentId) {
            console.log('Fetching settings for document:', documentId);
            fetchSettings();
        }
    }, [documentId]);

    const fetchSettings = async () => {
        try {
            const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/documents/settings?documentId=${documentId}`, token);
            if (prompt !== response.prompt) {
                setPrompt(response.prompt);
            }
            if (maxWordCount !== response.maxWordCount) {
                setMaxWordCount(response.maxWordCount);
            }
            
        } catch (error) {
            console.error('Error fetching document settings:', error);
        }
    };

    const updateSettings = async (field: string, value: string) => {
        try {
            await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/documents/settings`, token, {
                method: 'PATCH',
                body: JSON.stringify({ documentId, [field]: value })
            });
        } catch (error) {
            console.error('Error updating document settings:', error);
        }
    };

    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPrompt = e.target.value;
        setPrompt(newPrompt);
        updateSettings('prompt', newPrompt);
    };

    const handleMaxWordCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newMaxWordCount = e.target.value;
        setMaxWordCount(newMaxWordCount);
        updateSettings('maxWordCount', newMaxWordCount);
    };

    return (
        <div className="flex items-center space-x-4">
            <div className="flex flex-1 items-center space-x-2">
                <Subheading className="whitespace-nowrap">
                    Essay Prompt
                </Subheading>
                <Input
                    placeholder=""
                    value={prompt || ''}
                    onChange={handlePromptChange}
                    className="flex-grow"
                ></Input>
            </div>
            <div className="flex items-center space-x-2 ml-auto">
                <Subheading className="whitespace-nowrap">
                    Max Word Count
                </Subheading>
                <Input
                    placeholder="0"
                    value={maxWordCount || ''}
                    onChange={handleMaxWordCountChange}
                ></Input>
            </div>
        </div>
    );
};

export default DocumentSettings