import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Editor from '../components/Editor';
import { fetchWithAuth } from "../utils/fetchWithAuth";
import DocumentSettings from '../components/DocumentSettings';
import { Document } from '../utils/types';
import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Button } from '../components/button';

import { SidebarLayout } from '../components/sidebar-layout';
import { NavbarItem } from '../components/navbar';

import SideNavbar from './sideNavbar'
import { Divider } from '../components/divider';
import { Heading } from '../components/heading';

import { User } from '../utils/types';
import { Token } from '../utils/types';

import { useLocation } from 'react-router-dom';

interface DocumentPageProps {
    user: User;
    token: Token;
}

function DocumentPage({ user, token }: DocumentPageProps) {
    const location = useLocation();
    const documentId = location.pathname.split('/').pop(); // Extracts the last part of the URL
    const [currentDocument, setCurrentDocument] = useState<Document | null>(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (!user) return;
        if (documentId === "editor") return;
        console.log("Document ID changed to: ", documentId);
        const fetchDocument = async () => {
            if (documentId) {
                try {
                    const response = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/documents?id=${documentId}&userId=${user._id}`, token);
                    setCurrentDocument(response.document);
                } catch (error) {
                    console.error('Error fetching document:', error);
                }
            }
        };

        fetchDocument();
    }, [documentId]);

    const handleDocumentUpdate = async (text: string) => {
        if (documentId) {
            updateDocument(documentId, text);
        }
    };

    const updateDocument = async (id: string, text: string) => {
        const url = `${process.env.REACT_APP_PUBLIC_HOST}/api/documents`;
        const response = await fetchWithAuth(url, token, {
            method: 'PATCH',
            body: JSON.stringify({ id, text })
        });
        console.log("The document to be set is: ", response.document);

        // Update the current document state
        setCurrentDocument(prevDocument => ({
            ...prevDocument,
            text: response.document.text,
            _id: prevDocument?._id || '',
            title: prevDocument?.title || '',
            prompt: prevDocument?.prompt || '',
            wordCount: prevDocument?.wordCount || 0,
            dateCreated: prevDocument?.dateCreated || ''
        }));
    };

    const handleEdit = async () => {
        if (!currentDocument) return;

        setIsEditing(true); // Set editing to true

        if (!user) return;

        console.log("User:", user);
        console.log("Token:", token);

        try {
            const data = await fetchWithAuth(`${process.env.REACT_APP_PUBLIC_HOST}/api/ai/editText`, token, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ text: currentDocument.text, documentId: currentDocument._id, userId: user._id })
            });
            console.log("Received response from url:", `${process.env.REACT_APP_PUBLIC_HOST}/api/ai/editText`);
            console.log("Received edited text:", data.text);
            if (data && data.text) {
                handleDocumentUpdate(data.text);
            }
        } catch (error) {
            console.error('Error editing document:', error);
        } finally {
            setIsEditing(false); // Set editing to false
        }
    };

    return (
        <div className="flex w-full">
            <div className="flex w-full flex-col relative">
                {/* Topbar */}
                {documentId && (
                    <div className="w-full mt-1">
                        <div className="flex items-center space-x-4">
                            <Button
                                color="dark"
                                onClick={handleEdit}
                                {...(isEditing && { 'data-disabled': true })}
                            >
                                Edit Essay
                            </Button>

                            <div className="flex-grow">
                                <DocumentSettings documentId={documentId} token={token} />
                            </div>
                        </div>
                    </div>
                )}

                {documentId &&
                    <Divider className="my-5" soft />
                }

                {/* Editor */}
                <div className="flex flex-1">
                    {documentId && currentDocument ? (
                        <Editor
                            document={currentDocument}
                            selectedDocumentId={documentId}
                            onUpdate={handleDocumentUpdate}
                        />
                    ) : (
                        <div className="flex items-center justify-center w-full h-full">
                            {/* <p>Select a document to get started</p> */}
                        </div>
                    )}
                    {isEditing && (
                        <div className="absolute inset-0 flex items-center justify-center">
                            <FaSpinner className="text-black text-6xl animate-spin" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export { DocumentPage };