import './App.css';
import React, { useState, useEffect, memo } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import { Login } from './pages/Login';
import { Register } from './pages/Register';
import { Navigate } from 'react-router-dom';
import { Subscription } from './pages/Subscription';
import { fetchWithAuth } from './utils/fetchWithAuth';
import { DocumentMetaInfo } from './utils/types';
import { SidebarLayout } from './components/sidebar-layout';
import SideNavbar from './pages/sideNavbar';
import { DocumentPage } from './pages/DocumentPage';
import Settings from './pages/Settings'; // Updated import

import { isEqual } from 'lodash';

type ProtectedRouteProps = {
  children: React.ReactNode;
};

// const MemoizedSideNavbar = memo(SideNavbar);

function App() {
  const { user, token, isAuthenticating } = useAuth();
  const [documentsMetaInfo, setDocumentsMetaInfo] = useState<DocumentMetaInfo[]>([]);


  const navbar = (
    <div className="flex items-center space-x-4">
    </div>
  );

  const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    if (isAuthenticating) return null;
    if (!token) return <Navigate to="/login" />;
    return <>{children}</>;
  };

  useEffect(() => {
    if (user && documentsMetaInfo.length === 0) {
      fetchDocumentsMetaInfo();
    }
  }, [user]);

  const fetchDocumentsMetaInfo = async () => {
    try {
      if (!user) return;
      console.log("fetchDocumentsMetaInfo called");

      const response = await fetchWithAuth(
        `${process.env.REACT_APP_PUBLIC_HOST}/api/documents/?userId=${user._id}`,
        token
      );

      console.log('Fetched documents:', response.documents);
      console.log('Current documents:', documentsMetaInfo);

      if (response && Array.isArray(response.documents)) {
        setDocumentsMetaInfo((prevDocumentsMetaInfo) => {
          if (!isEqual(prevDocumentsMetaInfo, response.documents)) {
            console.log('Documents meta info is different, updating state');
            return response.documents;
          } else {
            console.log('Documents meta info is the same, not updating state');
            return prevDocumentsMetaInfo;
          }
        });
        console.log('New meta info:', documentsMetaInfo);
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  return (
    <div>
      <Routes>
        <Route 
          path="/login" 
          element={user && token ? <Navigate to="/editor" /> : <Login />} 
        />
        <Route 
          path="/register" 
          element={user && token ? <Navigate to="/editor" /> : <Register />} 
        />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SidebarLayout navbar={navbar} sidebar={<SideNavbar documentsMetaInfo={documentsMetaInfo} setDocumentsMetaInfo={setDocumentsMetaInfo} user={user} token={token} />} />
            </ProtectedRoute>
          }
        >
          <Route path="editor" element={<DocumentPage user={user} token={token} />} />
          <Route path="editor/:documentId" element={<DocumentPage user={user} token={token} />} />
          <Route path="editor/" element={<DocumentPage user={user} token={token} />} />
          <Route path="settings" element={<Settings user={user} token={token} />} />
          <Route path="/" element={<Navigate to="/editor/" />} />
        </Route>

        {/* <Route path="/product" element={<Subscription />} /> */}
        <Route path="*" element={<h1 className="text-black">Page Not found!</h1>} />
      </Routes>
    </div>
  );
}

export default App;
